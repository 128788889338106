<template>
	<div
		ref="episode_list_item_view"
		class=" size-px-14 bg-tv bg-dark color-gray02 flex-column" :class="items.length > 0 ? 'pb-30' : 'full-height'"
	>
		<div class="mt-50 ">
			<VideoJs
				v-if="item_video.cover.src"
				:item="item_video"
				:id="item_video.bulletin_number + 'view'"
				class="full-height width-100"
			></VideoJs>
			<div
				class="pa-20 color-white font-weight-500 size-px-16"
			>
				{{ item_series.title }}

				<div class="mt-15 size-px-14 color-white justify-start gap-5 items-center"><span class="badge_30 mr-10" :class="'badge_30_' + item_cartel.type + '_bl'"></span> {{ item_cartel.cartl_name }}</div>
			</div>
		</div>
		<div class="full-height flex-column ">
			<div class="ptb-10">
				<hr class="under-line" />
			</div>

			<div class="pa-20 size-px-16 font-weight-500 color-white">
				{{ $language.tv.title_tv_episode}}
			</div>

			<CartelBbsList
				v-if="items.length > 0"
				:user="user"
				:items_list="list_items"
				:max="max"
				:is_add="is_add"
				:item_search="item_search"
				:item_cartel="item_cartel"
				:from="from"
				:is_sample="true"

				@click="update"
			></CartelBbsList>
			<Empty
				v-else

				class="full-height"
			></Empty>
		</div>

		<PopupHalf
			v-if="is_on_more"
			:user="user"
			:title="title_half"

			@cancel="is_on_more = false"
		>
			<template
				v-slot:half_items
			>
				<li class="pb-10" @click="toEdit">{{ $language.common.modify }} <!--  수정 --></li>
				<li @click="onDelete()">{{ $language.common.delete }} <!-- 삭제 --></li>
			</template>
		</PopupHalf>

		<Popup_confirm
			v-if="is_on_delete"

			@cancel="is_on_delete = false"
			@click="postDelete"
		>
			<template v-slot:title>{{ $language.confirm.delete_bbs.title }}</template>
			<template
				v-slot:main-txt
			>{{ $language.confirm.delete_bbs.main }}</template>
			<template
				v-slot:sub-txt
			>{{ $language.confirm.delete_bbs.sub }}</template>

		</Popup_confirm>
		<!-- 게시물 상세보기 -->
		<cartel_bbs_detail
			v-if="is_item_detail"

			:user="user"
			:cartel="item_cartel"
			:bbs="item_detail"
			:is_item_detail_comment="is_item_detail_comment"

			class="layer-popup"

			@cancel="offDetail"
		></cartel_bbs_detail>

		<ScrollPagination
			v-if="$refs.episode_list_item_view"
			:body="$refs.episode_list_item_view"
			:item_search="item_search"
			:max="max"
			:is_add="is_add"

			@change="getSearch"
		></ScrollPagination>
	</div>
</template>

<script>

import Empty from "@/view/Layout/Empty";
import PopupHalf from "@/view/Layout/PopupHalf";
import Popup_confirm from "@/view/Layout/PopupConfirm";
import VideoJs from "@/components/VideoJs";
import cartel_bbs_detail from "@/view/Cartel/cartel_bbs_detail";
import CartelBbsList from "@/view/Cartel/CartelBbsList";
import ScrollPagination from "@/components/ScrollPagination";
export default {
	name: 'MafiaTvEpisode'
	, components: {ScrollPagination, CartelBbsList, cartel_bbs_detail, VideoJs, Popup_confirm, PopupHalf, Empty}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'MafiaTvEpisode'
				, title: this.$language.tv.title_tv_series_detail
				, type: 'cartel_sub'
				, not_footer: true
				, bg: 'bg-tv'
				, from: this.$route.query.from ?
					{
						name: 'MafiaTvList'
					}
					: {
						name: 'MafiaTvList'
						, params: {
							idx: this.$route.params.idx
							, code: this.$route.params.code
							, b_id: this.$route.params.b_id
					}
				}
			}
			, item_search: {
				srchtext: ''
				, page_number: 1
				, pagerecnum: 10
			}
			, items: [

			]
			, item_more: {}
			, is_on_more: false
			, is_on_delete: false
			, title_half: this.$language.notice.article
			, view_type: 'episode'
			, item_search_episode: {
				page_number: 1
				, pagerecnum: 10
			}
			, item_series: {

			}
			, item_video: {
				video: {
					src: ''
				}
				, cover: {
					src: ''
				}
			}
			, item_cartel: {

			}
			, mode: ''
			, is_action: ''
			, is_item_detail: false
			, item_detail: {}
			, is_item_detail_comment: false
			, max: false
			, is_add: true
			, from: {
				name: 'MafiaTvEpisode'
				, params: {
					idx: this.$route.params.idx
					, code: this.$route.params.code
					, b_id: this.$route.params.b_id
					, p_id: this.$route.params.p_id
				}
			}
		}

	}
	, computed: {
		list_items: function(){
			return this.items.filter( (item) => {
				if(this.view_type == 'series'){
					item.cover_img = item.post_file_list[0].post_appendix_file_org_url
				}else{
					item.post_file_list.filter( (file) => {
						if(file.post_appendix_file_div_code == 'CA01000001'){
							item.cover_img = file.post_appendix_file_org_url
						}
					})
				}
				return item
			})
		}
		, post_sort_list: function(){
			let t = []
			this.items.filter( (item, index) => {
				t.push({
					bulletin_number: item.bulletin_number
					, sort_seqen: index + 1
				})
			})
			return t
		}
	}
	, methods: {
		getData: async function(){
			try {
				let url = this.$api_url.api_path.get_open_tv_series_episode
				if(this.user.member_number){
					url = this.$api_url.api_path.get_cartel_tv_episode_list
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, parents_post_number: this.$route.params.p_id
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.item_series = result.data.parents_post_info

					if(result.data.post_list.length > 0){
						if(this.item_search.page_number == 1){
							this.items = result.data.post_list
							this.item_video.cover.src = this.item_series.post_file_list[0].post_appendix_file_org_url

							if(this.items.length > 0){
								let files = this.items[0].post_file_list
								for(let i = 0; i < files.length; i++){
									let file = files[i]
									if(file.post_appendix_file_div_code == 'CA01000001'){
										// this.item_video.cover.src = file.post_appendix_file_org_url
									}else{
										// this.item_video.video.src = file.post_appendix_file_org_url
									}
								}
							}
						}else{
							this.items = this.items.concat(result.data.post_list)
						}
						this.max = false
					}else{
						this.max = true
					}

					console.log('this.item_series', this.item_series)
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onMore: function(item){
			this.is_on_more = true
			this.item_more = item
		}
		, toEdit: function(){
			this.$bus.$emit('to', { name: 'MafiaTvEdit', params: {idx: this.$route.params.idx, b_code: this.$route.params.b_code, b_id: this.$route.params.b_id, bbs_id: this.item_more.bulletin_number}})
		}
		, onDelete: function(item){
			if(item){
				this.item_more = item
			}
			this.is_on_delete = true
		}
		, postDelete: async function(){
			try {

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_delete_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.item_more.board_number
						, bulletin_number: this.item_more.bulletin_number
					}
					, type: true
				})

				if (result.success) {
					await this.getData()
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
				this.is_on_delete = false
				this.is_on_more = false
			}
		}
		, toItem: function(){
			switch (this.view_type){
				case 'series':
					this.$emit('to', {name: 'MafiaTvSeries', params: {idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, type: this.view_type}})
					break;
				case 'alone':
					this.$emit('to', {name: 'MafiaTvItem', params: {idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, type: this.view_type}})
					break;
				case 'episode':
					this.$emit('to', {name: 'MafiaTvItem', params: {idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, p_id: this.item_series.bulletin_number, type: this.view_type}})
					break;
			}
		}
		, getEpisode: async function(series){
			try {

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_tv_episode_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: series.board_number
						, parents_post_number: series.bulletin_number
						, page_number: this.item_search_episode.page_number
						, pagerecnum: this.item_search_episode.pagerecnum
					}
					, type: true
				})

				if (result.success) {
					this.items = result.data.post_list
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
				this.is_on_delete = false
				this.is_on_more = false
			}
		}
		, toEpisode: function(item){
			this.view_type = 'episode'
			this.item_series = item
			this.$bus.$emit('to', { name: 'MafiaTvManageList', params: { idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, p_id: this.item_series.bulletin_number }})
			this.program.from = ''
			this.getEpisode(item)
		}
		, getCartel: async function(){
			try{
				let url = this.$api_url.api_path.get_open_cartel_info
				if(this.user.member_number){
					url = this.$api_url.api_path.get_cartel_info
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
						, page_number: 1
						, pagerecnum: 10
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data

					switch (this.item_cartel.cartl_concern_sphere_code){
						case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
						case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
						case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
						case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setAction: function ($e){
			console.log('e', $e)
			this.is_action = $e.oldIndex
		}
		, postSort: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_tv_episod_sort
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, parents_post_number: this.$route.params.p_id
						, post_list: this.post_sort_list
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.mode = ''
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item){
			this.is_item_detail = true
			this.item_detail = item
			// this.$bus.$emit('to', {name: 'MafiaTvList', params: {idx: this.$route.params.idx, b_id: this.$route.params.b_id, bbs_id: item.bulletin_number}, hash: 'detail'})
		}
		, offDetail: function(){
			this.is_item_detail = false
			this.item_detail = {}
		}
		, update: function(item){

			console.log('update', item.index, item)
			this.$set(this.items, item.index, item)
		}
		, getSearch: function(page){
			this.item_search.page_number = page
			this.getData()
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getCartel()
		this.getData()

		document.body.scrollTo({ top: 0, behavior: "smooth"})
	}
}
</script>

<style>
.box-120-80 {
	width: 120px; height: 80px;
	border-radius: 8px;
}
.box-130-70 {
	width: 130px; height: 70px;
	border-radius: 8px;
}

.white-space {
	width: 120px;
	white-space: normal;
	text-overflow: ellipsis;
	word-break: break-all;
	overflow: hidden
}


.btn-tv{
	width: 62px;
	height: 30px;
	padding: 9px 14px;
	display: flex;
	justify-content: center;
	align-items: center;

	color: var(--blue02, #293097);
	text-align: center;
	font-family: "Noto Sans CJK KR";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%; /* 12px */
}

.btn-blue {
	background-color: var(--blue02);
	color: var(--white);
}
.btn-blue-outline {
	border: 1px solid var(--blue02);
	color: var(--blue02);
}
</style>