<template>
	<div class="half_layer_wrap popup">
		<div
			class="half_layer_wrap_inner "
			:class="is_ready ? 'slide-up' : 'slide-down'"
		>
			<article class="half_view">
				<div class="layer_title">
					<h3 class="color-blue02">{{ title }}</h3>
				</div>
				<div class="hlayer">
					<div class="create_wallet">
						<ul class="mt-10">
							<slot
								name="half_items"
							></slot>
						</ul>
					</div>
				</div>
			</article>
			<button
				@click="$emit('cancel')"
				class="btn_close">
				<i class="icon icon-close"></i>
			</button>
		</div>
		<div class="bg_overlay"></div>

	</div>
</template>
<script>
	export default {
		name: 'PopupHalf'
		, props: ['user', 'title']
		, data: function(){
			return {
				is_ready: false
			}
		}
		, created() {
			setTimeout( () => {
				this.is_ready = true
			}, 0)
		}

	}
</script>